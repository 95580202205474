import { Dialog, Transition } from '@headlessui/react';
import { X as CloseIcon } from 'kzv-icons';
import { Fragment, useEffect, useRef } from 'react';
import { useKey } from 'react-use';
import { useRouter } from 'next/router';

interface BaseModalProps {
    open: boolean;
    handleClose: () => void;
    children: React.ReactNode;
}

export default function BaseModal({ open, children, handleClose }: BaseModalProps) {
    const cancelButtonRef = useRef(null);
    const router = useRouter();

    useEffect(() => {
        router.events.on('routeChangeStart', handleClose);
        return () => {
            router.events.off('routeChangeStart', handleClose);
        };
    }, []);

    useKey('Escape', handleClose);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-[110] inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={handleClose}
            >
                <div className="w-full h-full sm:w-auto sm:h-auto text-center sm:block">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity" />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="w-full h-full sm:w-auto sm:h-auto relative inline-block align-bottom bg-white sm:rounded-2xl text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle">
                            <button
                                className="hidden sm:block absolute -top-8 -right-8 text-2xl text-white focus:outline-none focus:ring-0"
                                ref={cancelButtonRef}
                                onClick={handleClose}
                            >
                                <CloseIcon strokeWidth={2} />
                            </button>
                            <div className="w-full h-full overflow-y-auto sm:rounded-2xl">{children}</div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
