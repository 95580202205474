import { AutocompleteComponents, AutocompletePlugin, getAlgoliaResults } from '@algolia/autocomplete-js';
import { PuscicaDesno } from 'kzv-icons';
import { ALGOLIA_AREAS_INDEX_NAME } from 'components/search/autocomplete/constants';
import { AreaHit } from 'components/search/autocomplete/example/types/AreaHit';
import { searchClient } from 'components/search/autocomplete/searchClient';
import Link from 'next/link';

export const areasPlugin: AutocompletePlugin<AreaHit, unknown> = {
    getSources({ query }) {
        if (!query) {
            return [];
        }

        return [
            {
                sourceId: 'areasPlugin',
                getItems() {
                    return getAlgoliaResults({
                        searchClient,
                        queries: [
                            {
                                indexName: ALGOLIA_AREAS_INDEX_NAME,
                                query,
                                params: {
                                    hitsPerPage: 1,
                                    highlightPreTag: '<mark className="bg-brand">',
                                    highlightPostTag: '</mark>'
                                }
                            }
                        ]
                    });
                },
                getItemUrl({ item }) {
                    const currentPath = window?.location?.pathname;
                    if (currentPath?.includes('/foto-izbor')) {
                        return `/ideje/${item.slug}/nagradni-izbor`;
                    } else {
                        return `/ideje/${item.slug}`;
                    }
                },
                getItemInputValue({ item }) {
                    return item.name;
                },
                templates: {
                    item({ item, components }) {
                        return <AreaItem hit={item} components={components} />;
                    }
                }
            }
        ];
    }
};

type AreaItemProps = {
    hit: AreaHit;
    components: AutocompleteComponents;
};

function AreaItem({ hit, components }: AreaItemProps) {
    return (
        <Link href={`/ideje/${hit.slug}`}>
            <div className="p-4 flex items-center">
                <div className="flex-grow">
                    <div className="leading-none">
                        <components.Highlight hit={hit} attribute="name" />
                    </div>
                </div>
                <div className="text-2xl leading-none">
                    <button type="button" title="Select" style={{ pointerEvents: 'none' }}>
                        <PuscicaDesno />
                    </button>
                </div>
            </div>
        </Link>
    );
}
