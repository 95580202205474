import { UserProfile } from 'models/UserProfile';

const mapUserProfile = (userData): UserProfile => {
    if (!userData) return null;
    return {
        id: userData.id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        avatar: userData.avatar,
        created_at: userData.created_at,
        email_verified_at: userData.email_verified_at,
        completed_registration_at: userData.completed_registration_at,
        municipality: userData.municipality,
        newsletter: userData.newsletter,
        area_id: userData.area_id
    };
};

export default mapUserProfile;
