import { useCookies } from 'react-cookie';
import { accessTokenCookieName } from 'data/constants';
import { useContext, useMemo } from 'react';
import { setUser } from 'contexts/AppActions';
import AppContext from 'contexts/AppContext';
import { UserProfile } from 'models/UserProfile';
import customAxios from 'data/axios';
import { useQueryClient } from 'react-query';

const useSession = () => {
    const queryClient = useQueryClient();
    const { dispatch } = useContext(AppContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies([accessTokenCookieName]);
    const token = useMemo(() => cookies[accessTokenCookieName], [cookies]);
    const setSessionCookie = (accessToken: string) => {
        setCookie(accessTokenCookieName, accessToken, {
            domain: process.env.NEXT_PUBLIC_DOMAIN,
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        });
    };

    const login = (accessToken: string, user: UserProfile) => {
        // Set session cookie and set user in context
        setSessionCookie(accessToken);
        customAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setUser(dispatch, user);
    };
    const logout = () => {
        queryClient.clear();
        // Remove session cookie and remove user from context
        removeCookie(accessTokenCookieName, { path: '/', domain: '.kzv.si' });
        removeCookie(accessTokenCookieName, { path: '/', domain: '.staging.kzv.si' });
        removeCookie(accessTokenCookieName, { path: '/', domain: '.kamzavikend.si' });
        removeCookie(accessTokenCookieName, { path: '/', domain: 'localhost' });
        delete customAxios.defaults.headers.common['Authorization'];
        setUser(dispatch, null);
    };

    return { token, login, logout, setSessionCookie };
};

export default useSession;
