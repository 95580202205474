const pages = {
    home: {
        title: '',
        url: '/',
        seo: {
            title: 'Kam za vikend - Več kot 2500 idej za izlet',
            description: 'Največja zbirka idej za izlet po Sloveniji.'
        }
    },
    namigi: {
        title: 'Namigi',
        url: '/namigi',
        seo: {
            title: '',
            description: ''
        }
    },
    ideas: {
        title: 'Vse ideje za izlet',
        url: '/ideje',
        seo: {
            title: 'Več kot 2500 idej za izlet po Sloveniji',
            description:
                'Razišči največjo zbirko izletniški idej po Sloveniji. Raziskuješ lahko med hribi, jezeri, gradovi, slapovi ...',
            url: 'https://kamzavikend.si/ideje'
        },

        area: (area) => ({
            title: `${area?.name}`,
            url: `/ideje/${area?.slug}`,
            seo: {
                url: `https://kamzavikend.si/ideje/${area.slug}`,
                description: `Raizšči številne izletniške ideje znotraj območja ${area?.name}`
            }
        }),
        category: (category) => ({
            title: `${category.name}`,
            url: `/ideje/${category.slug}`,
            seo: {
                url: `https://kamzavikend.si/ideje/${category.slug}`,
                description: `Raizšči številne izletniške ideje v kategoriji ${category.name}`
            }
        }),
        areaCategory: (area, category) => ({
            title: `${area.name} - ${category.name}`,
            url: `/ideje/${area.slug}/${category.slug}`,
            seo: {
                url: `https://kamzavikend.si/ideje/${area.slug}/${category.slug}`,
                description: `Raizšči številne izletniške ideje znotraj območja ${area.name} in kategorije ${category.name}.`
            }
        })
    },
    idea: (poi, description) => ({
        title: poi.name,
        url: `/ideja/${poi.slug}`,
        seo: {
            url: `https://kamzavikend.si/ideja/${poi.slug}`,
            description: description.content
                ? truncate(description.content.replace(/(<([^>]+)>)/gi, ''), 200, true)
                : description
        }
    }),
    contact: {
        url: '/kontakt',
        title: 'Kontakt',
        seo: {
            title: '',
            description: '',
            keywords: ''
        }
    },
    about: {
        url: '/o-projektu',
        title: 'O projektu',
        seo: {
            title: '',
            description: '',
            keywords: ''
        }
    },
    suggest: {
        url: '/predlagaj-idejo',
        title: 'Predlagaj izletno idejo',
        seo: {
            title: '',
            description: '',
            keywords: ''
        }
    },
    cookies: {
        url: '/piskotki',
        title: 'Piškotki',
        seo: {
            title: '',
            description: '',
            keywords: ''
        }
    },
    photoContest: {
        url: '/foto-izbor',
        title: 'Nagradni foto izbor',
        seo: {
            title: 'Sodeluj tudi ti v nagradnem izboru fotografij Slovenije.',
            description:
                'Osvoji Nikon Z 30 v vrednosti 799,99 €. Na kamzavikend.si poišči izletne točke ujete na tvojih fotkah in jih preprosto naloži. '
        }
    },
    photoContestVote: {
        url: '/foto-izbor/finalisti',
        title: 'Glasuj za najlepšo fotografijo',
        seo: {
            title: 'Glasuj za najlepšo fotografijo',
            description: 'Glasuj za najlepšo fotografijo Slovenije in osvoji KZV majico'
        }
    },
    photoContestWinner: {
        url: '/foto-izbor/zmagovalec'
    }
};

function truncate(str, n, useWordBoundary) {
    if (str.length <= n) {
        return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString;
}

export default pages;
