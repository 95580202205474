import React, { InputHTMLAttributes, useState } from 'react';
import { useField } from 'formik';
import { Eye, EyeOff } from 'react-feather';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    type?: string;
    autocomplete?: string;
}

function FormikInputField({
    type = 'text',
    name,
    label = '',
    placeholder = '',
    helperText = '',
    autocomplete = '',
    ...rest
}: InputFieldProps) {
    const [field, meta] = useField(name);
    const [fieldType, setFieldType] = useState<string>(type);

    return (
        <div className="space-y-1">
            {label && (
                <label className="pl-4 text-brand font-semibold" htmlFor={name}>
                    {label}
                </label>
            )}
            <div className="relative">
                <input
                    id={name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type={fieldType}
                    name={name}
                    placeholder={placeholder}
                    autoComplete={autocomplete ?? name}
                    className={`rounded-full border border-gray-500 py-2 px-4 shadow-sm focus:outline-none focus:ring-brand focus:ring-2 w-full whitespace-nowrap overflow-hidden text-ellipsis ${
                        meta.error && meta.touched ? 'border-red-500' : ''
                    }`}
                    {...rest}
                />
                {type === 'password' && (
                    <div className="absolute top-0 right-0 h-full flex items-center pr-4">
                        <button
                            type="button"
                            className="focus:outline-none"
                            onClick={() => {
                                setFieldType(fieldType === 'password' ? 'text' : 'password');
                            }}
                        >
                            {fieldType === 'password' ? <Eye size={20} /> : <EyeOff size={20} />}
                        </button>
                    </div>
                )}
            </div>

            {helperText && !meta.error && <div className={`pl-4 text-xs text-left text-secondary`}>{helperText}</div>}
            {meta.error && meta.touched && <div className={`pl-4 text-xs text-left text-danger`}>{meta.error}</div>}
        </div>
    );
}

export default FormikInputField;
