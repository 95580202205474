import mixpanel, { Query, Dict } from 'mixpanel-browser';
const env = process.env.NODE_ENV;
const mixpanelId = '7f7f91fd401afafe071a5366a0122acf';

mixpanel.init(mixpanelId, {
    api_host: 'https://api-eu.mixpanel.com',
    debug: env === 'development'
});

export const Mixpanel = {
    identify: (id: string) => {
        mixpanel.identify(id);
    },
    alias: (id: string) => {
        mixpanel.alias(id);
    },
    track: (name: string, props?: Dict) => {
        mixpanel.track(name, props);
    },
    trackLinks: (query: Query, name: string) => {
        mixpanel.track_links(query, name, {
            referrer: document.referrer
        });
    },
    people: {
        set: (props: Dict) => {
            mixpanel.people.set(props);
        }
    }
};
