import AppContext from 'contexts/AppContext';
import { useContext, useEffect } from 'react';
import { setClientLocation } from 'contexts/AppActions';
import { IGeolocationPositionError } from 'react-use/lib/useGeolocation';
import { useLocalStorage } from 'react-use';

const storedPermissionKey = 'clientLocationPermission';
const useClientLocation = (options?: PositionOptions) => {
    const [storedPermission, setStoredPermission] = useLocalStorage(storedPermissionKey, false);
    const { state, dispatch } = useContext(AppContext);
    const { clientLocation } = state;
    let mounted = true;

    const onEvent = (event: any) => {
        if (mounted) {
            setStoredPermission(true);
            setClientLocation(dispatch, {
                latitude: event.coords.latitude,
                longitude: event.coords.longitude,
                timestamp: event.timestamp,
                loading: false
            });
        }
    };
    const onEventError = (error: IGeolocationPositionError) => {
        if (mounted) {
            setClientLocation(dispatch, { ...clientLocation, loading: false, error });
            setStoredPermission(false);
        }
    };

    const requestClientLocation = () => {
        setClientLocation(dispatch, { ...clientLocation, loading: true });
        navigator.geolocation.getCurrentPosition(onEvent, onEventError, options);
    };

    useEffect(() => {
        if (storedPermission) {
            requestClientLocation();
        }
        return () => {
            mounted = false;
        };
    }, []);

    return { clientLocation, requestClientLocation, storedPermission };
};

export default useClientLocation;
