import '../styles/global.css';
import 'react-tippy/dist/tippy.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { AppContextProvider } from 'contexts/AppContext';
import swrConfig from 'data/swrConfig';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SWRConfig } from 'swr';
import MainMenu from 'components/navigation/MainMenu';

import CookieSettings from '../components/CookieSettings';
import SearchModal from 'components/search/autocomplete/SearchModal';
import SEO from '../config/seo';
import { Mixpanel } from 'utils/Mixpanel';
import useClientLocation from 'hooks/useClientLocation';
import LoginModal from 'components/modals/LoginModal';
import { CookiesProvider } from 'react-cookie';
import dynamic from 'next/dynamic';
import HeadCurve from 'components/curves/HeadCurve';

function MyApp({ Component, pageProps }) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: Infinity
                    }
                }
            })
    );
    const router = useRouter();
    useClientLocation();

    const PhotoContestTopNoticeBar = dynamic(() => import('../components/photo-contest/PhotoContestTopNoticeBar'), {
        ssr: false
    });

    useEffect(() => {
        const handleRouteChangeComplete = () => {
            NProgress.done();
            Mixpanel.track('Page loaded');
        };

        const handleRouteChangeStart = () => {
            NProgress.start();
        };

        Mixpanel.track('Page loaded');

        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        router.events.on('routeChangeStart', handleRouteChangeStart);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
            router.events.off('routeChangeStart', handleRouteChangeStart);
        };
    }, [router.events]);

    return (
        <CookiesProvider>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    <Hydrate state={pageProps.dehydratedState}>
                        <SWRConfig value={{ ...swrConfig }}>
                            <DefaultSeo {...SEO} />
                            <Head>
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                            </Head>
                            <PhotoContestTopNoticeBar />
                            <HeadCurve />
                            <MainMenu />
                            <Component {...pageProps} />
                            <SearchModal />
                            <LoginModal />
                            <CookieSettings />
                            <Toaster position="bottom-left" reverseOrder={false} />
                        </SWRConfig>
                        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
                    </Hydrate>
                </AppContextProvider>
            </QueryClientProvider>
        </CookiesProvider>
    );
}

export default MyApp;
