import React from 'react';

import IconButton from './IconButton';

function HamburgerButton({ handleClick, isActive }) {
    const barClasses = 'h-[2px] bg-gray-600 border-current rounded-md transform-gpu transition-all ease-linear';

    const firstBarAnimation = 'translate-y-[7px] rotate-[45deg]';
    const secondBarAnimation = 'translate-x-[-15px]  opacity-0';
    const thirdBarAnimation = 'translate-y-[-5px] rotate-[-45deg]';

    return (
        <IconButton onClick={handleClick}>
            <div className="flex w-5 h-5 flex-col justify-center">
                <div className={`${barClasses} ${isActive ? firstBarAnimation : ''}`} />
                <div className={`mt-1 ${barClasses} ${isActive ? secondBarAnimation : ''}`} />
                <div className={`mt-1 ${barClasses} ${isActive ? thirdBarAnimation : ''}`} />
            </div>
        </IconButton>
    );
}

export default HamburgerButton;
