import NextLink from 'next/link';
import React, { ReactNode } from 'react';

interface LinkProps {
    to: string;
    external?: boolean;
    target?: string;
    styles?: string;
    prefetch?: boolean;
    children?: ReactNode;
}

function Link({ to = '#', external = false, target, children, styles = '', prefetch = true, ...rest }: LinkProps) {
    const linkAttrs = {
        href: to
    };

    const prefetchProp = !prefetch ? { prefetch: false } : {};

    if (external || target === '_blank') {
        const extraAttrs = {
            rel: ''
        };
        if (target === '_blank') {
            extraAttrs.rel = 'noopener noreferrer';
        }

        return (
            <a className={styles} href={to} target={target} {...extraAttrs} {...rest}>
                {children}
            </a>
        );
    } else {
        return (
            <NextLink className={styles} {...linkAttrs} {...prefetchProp} {...rest}>
                {children}
            </NextLink>
        );
    }
}

export default Link;
