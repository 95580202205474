export default {
    defaultTitle: 'Kam za vikend | Največ idej za izlet po Sloveniji',
    titleTemplate: '%s | Kam za vikend',
    description:
        'Kam za vikend je največja zbirka idej za izlet po Sloveniji. Raziskuj med več kot 2500 idejami za izlet po celotni Sloveniji.',
    keywords: 'izleti, ideje za izlet, izletniške ideje, izleti po Sloveniji',
    canonical: 'https://kamzavikend.si',
    openGraph: {
        title: 'Kam za vikend | Največ idej za izlet po Sloveniji',
        type: 'website',
        locale: 'sl_SI',
        site_name: 'Kam za vikend',
        description:
            'Kam za vikend je največja zbirka idej za izlet po Sloveniji. Raziskuj med več kot 2500 idejami za izlet po celotni Sloveniji.',
        images: [{ url: 'https://kamzavikend.si/images/share.png' }]
    },
    facebook: {
        appId: '943888656539079'
    }
};
