import React from 'react';
import { PuscicaDesno, SlovenijaPin, VBlizini } from 'kzv-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';

const AutocompleteFocusedDropdown = () => {
    const { pathname } = useRouter();
    const isContestPage = pathname?.includes('/foto-izbor');
    const nearbyUrl = isContestPage ? '/ideje/nagradni-izbor/?sort=nearby' : '/ideje?sort=nearby';
    const allIdeasUrl = isContestPage ? '/ideje/nagradni-izbor' : '/ideje';
    return (
        <div className={`mt-2 overflow-hidden`}>
            <Link
                href={nearbyUrl}
                className="flex items-center justify-between gap-4  px-3 py-3 border-l-4 border-l-transparent hover:border-l-brand hover:bg-gray-50 border-b border-gray-400 focus:bg-gray-100 focus:border-l-brand focus:outline-none"
            >
                <span className="inline-flex items-center gap-2">
                    <span className={'text-xl'}>
                        <VBlizini />
                    </span>
                    <span>Išči v moji bližini</span>
                </span>
                <span className={'text-xl'}>
                    <PuscicaDesno />
                </span>
            </Link>
            <Link
                href={allIdeasUrl}
                className="flex items-center justify-between gap-4 px-3 py-3 border-l-4 border-l-transparent hover:border-brand hover:bg-gray-50 focus:bg-gray-100 focus:border-l-brand focus:outline-none"
            >
                <span className="inline-flex items-center gap-2">
                    <span className={'text-xl'}>
                        <SlovenijaPin />
                    </span>
                    <span>Išči po celotni Sloveniji</span>
                </span>
                <span className={'text-xl'}>
                    <PuscicaDesno />
                </span>
            </Link>
        </div>
    );
};

export default AutocompleteFocusedDropdown;
