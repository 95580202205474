/* eslint-disable react/display-name */
import Link from 'components/links/Link';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const buttonStyles = {
    general: ' rounded-3xl tracking-wide focus:outline-none transition-colors flex items-center w-full justify-center',
    contained: {
        outer: 'inline-block rounded-3xl shadow-lg',
        primary:
            'px-6 py-2 text-white bg-brand-medium hover:bg-brand active:shadow-inner focus:ring-2 focus:ring-brand-dark',
        secondary:
            'px-6 py-2 text-white bg-gray-700 hover:bg-gray-500 active:shadow-inner focus:ring-2 focus:ring-brand-dark',
        white: 'px-6 py-2 text-primary bg-white hover:bg-gray-100 active:shadow-inner focus:ring-2 focus:ring-brand-dark'
    },
    text: {
        outer: 'inline-block text-md rounded-xl',
        primary: 'font-medium px-4 py-2 text-brand',
        secondary: 'font-medium px-4 py-2 text-black hover:bg-gray-100 focus:ring-1 focus:ring-brand-dark'
    },
    outlined: {
        outer: 'inline-block rounded-3xl',
        primary:
            'px-4 md:px-6 py-2 text-primary border border-gray-300 bg-white hover:bg-gray-200 active:shadow-inner focus:ring-1 focus:ring-brand',
        secondary:
            'px-4 md:px-6 py-2 text-primary border border-gray-300 bg-white hover:bg-gray-200 active:shadow-inner focus:ring-1 focus:ring-brand'
    }
};

function ButtonWrapper({ styles, children }) {
    return <span className={styles}>{children}</span>;
}

const BaseElement = forwardRef(({ styles, link, external, target, children, type = 'button', ...rest }, ref) => {
    if (link) {
        return (
            <Link to={link} styles={styles} external={external} target={target} ref={ref} {...rest}>
                {children}
            </Link>
        );
    }
    return (
        <button className={styles} type={type} {...rest} ref={ref}>
            {children}
        </button>
    );
});

function Button(
    {
        variant = 'contained',
        color = 'primary',
        isActive,
        children,
        link,
        target,
        external = false,
        styles = '',
        outerStyles = '',
        endIcon,
        startIcon,
        disabled,
        ...rest
    },
    ref
) {
    let appliedStyles = buttonStyles.general;

    if (variant && color) {
        appliedStyles += ` ${buttonStyles[variant][color]}`;
    }

    if (isActive) {
        appliedStyles += ' border-brand';
    }

    if (disabled) {
        appliedStyles += ' opacity-50 pointer-events-none bg-gray-500';
    }

    return (
        <ButtonWrapper styles={`${buttonStyles[variant]?.outer} ${outerStyles}`}>
            <BaseElement
                link={link}
                external={external}
                target={target}
                styles={`${appliedStyles} ${styles}`}
                ref={ref}
                disabled={disabled}
                {...rest}
            >
                {startIcon && <span className="mr-2 text-xl">{startIcon}</span>}
                {children}
                {endIcon && <span className="ml-2 text-xl">{endIcon}</span>}
            </BaseElement>
        </ButtonWrapper>
    );
}

Button.propTypes = {
    variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
    color: PropTypes.oneOf(['primary', 'secondary', 'white']),
    styles: PropTypes.string,
    link: PropTypes.string,
    endIcon: PropTypes.node,
    startIcon: PropTypes.node,
    external: PropTypes.bool,
    target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top'])
};

export default forwardRef(Button);
