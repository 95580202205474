import Link from 'components/links/Link';
import React, { useContext } from 'react';

import socials from '../../config/socials';
import AppContext from 'contexts/AppContext';
import { setSearchDialogActive } from 'contexts/AppActions';
import { Lupa } from 'kzv-icons';

function MobileMenu({ links }, ref) {
    const { dispatch } = useContext(AppContext);

    return (
        <nav
            className="z-[50] bg-white p-2 flex items-center flex-col text-center fixed inset-0 md:max-w-sm ml-auto shadow-2xl pt-16"
            ref={ref}
        >
            <div className="h-full flex justify-center flex-col">
                <div className="">
                    <button
                        className="p-4 uppercase font-bold text-xl tracking-wide hover:text-brand transition inline-flex items-center gap-2"
                        onClick={() => setSearchDialogActive(dispatch, true)}
                    >
                        Iskalnik <Lupa />
                    </button>
                </div>
                {links.map((link, i) => (
                    <div className="my-4" key={i}>
                        <Link
                            styles="p-4 uppercase font-bold text-xl tracking-wide hover:text-brand transition"
                            to={link.href}
                        >
                            {link.label}
                        </Link>
                    </div>
                ))}
            </div>

            <div className="mt-3 border-b border-gray-100 flex-grow w-[75%]" />

            <div className="mt-6">
                <p className="text-secondary text-sm">Ideje za izlet tudi na</p>
                <div className="grid grid-cols-3 gap-2">
                    <a href={socials.instagram} className="p-4" target="_blank" rel="noreferrer">
                        <img src="/images/icons/instagram-color.svg" alt="KZV na Instagramu" className="h-8 mx-auto" />
                    </a>
                    <a href={socials.facebook} className="p-4" target="_blank" rel="noreferrer">
                        <img src="/images/icons/facebook-color.svg" alt="KZV na Facebooku" className="h-8 mx-auto" />
                    </a>
                    <a href={socials.youtube} className="p-4" target="_blank" rel="noreferrer">
                        <img src="/images/icons/youtube-color.svg" alt="KZV na Youtubu" className="h-8 mx-auto" />
                    </a>
                </div>
            </div>
        </nav>
    );
}

export default React.forwardRef(MobileMenu);
