import { useContext } from 'react';
import AppContext from 'contexts/AppContext';

const useUser = () => {
    const { state } = useContext(AppContext);

    return {
        user: state?.user?.profile,
        loggingOut: state.user.loggingOut
    };
};

export default useUser;
