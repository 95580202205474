import * as React from 'react';

function HeadCurveSvg(props) {
    return (
        <svg
            width={1310}
            height={35}
            viewBox="0 0 1310 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMinYMax slice"
            {...props}
        >
            <path
                d="M8 30.17c42.037-6.84 82.564-2.689 124.586-1.846 34.238.686 68.387-2.229 102.535-1.814 28.799.35 57.4 3.66 86.217 3.66 35.934 0 71.55-.91 107.387-.016 24.196.603 48.559-.507 72.987-.086 26.229.452 52.485.102 78.72.102 20.292 0 40.358 1.83 60.639 1.83 22.325 0 44.187-3.56 66.483-2.646 82.916 3.398 166.99-.905 250.053-3.047 47.613-1.229 94.923 1.604 142.553 2.022 32.38.284 64.87-.205 97.25.824 23.64.752 49.32-1.97 72.76 1.119 5.83.767 10.31.07 16.04.61 1.19.112 2.41-.094 3.61-.199 5.36-.47 16.86 2.603 10.12-.502"
                stroke="#FBA919"
                strokeWidth={15}
                strokeMiterlimit={1.414}
                strokeLinecap="round"
            />
            <path fill="#FBA919" d="M4 0h1301v26H4z" />
        </svg>
    );
}

export default HeadCurveSvg;
