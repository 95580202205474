import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormikInputField from 'components/forms/fields/FormikInputField';
import Button from 'components/buttons/Button';
import Link from 'next/link';
import axios from 'data/axios';
import toast from 'react-hot-toast';
import useSession from 'hooks/useSession';
import mapUserProfile from 'models/mapping/mapUserProfile';
import { setLoginDialogActive } from 'contexts/AppActions';
import AppContext from 'contexts/AppContext';
import { UserProfile } from 'models/UserProfile';

const initialValues = {
    email: '',
    password: ''
};

const schema = Yup.object().shape({
    email: Yup.string().required('Vnesi svoj e-poštni naslov'),
    password: Yup.string().required('Vnesi svoje geslo')
});

const LoginForm = ({ onLoginSuccess }: { onLoginSuccess?: (user: UserProfile) => void }) => {
    const { login } = useSession();
    const { dispatch } = useContext(AppContext);
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await axios.post('/public/login', values);
            const { access_token, user } = response.data;
            const userProfile = mapUserProfile(user);
            login(access_token, userProfile);
            if (typeof onLoginSuccess === 'function') {
                onLoginSuccess(userProfile);
            }
            setSubmitting(false);
        } catch (e) {
            console.log(e);
            setSubmitting(false);
            toast.error('Prijava ni uspela. Poskusi ponovno.');
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} validateOnBlur={true}>
            {({ isSubmitting, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <FormikInputField
                        name="email"
                        label="E-pošta"
                        type={'email'}
                        placeholder="Vnesi svoj e-poštni naslov"
                        autocomplete="username"
                    />
                    <div className="mt-4">
                        <FormikInputField
                            name="password"
                            label="Geslo"
                            type="password"
                            placeholder="Vnesi svoje geslo"
                            autocomplete={'current-password'}
                        />
                    </div>
                    <div className="mt-1 text-right text-xs text-secondary">
                        <Link
                            href="/pozabljeno-geslo"
                            className="underline"
                            onClick={() => setLoginDialogActive(dispatch, false)}
                        >
                            Pozabljeno geslo?
                        </Link>
                    </div>
                    <div className="flex flex-col mt-4">
                        <Button color={'secondary'} variant={'contained'} type={'submit'} disabled={isSubmitting}>
                            <span className="font-bold">Prijavi se</span>
                        </Button>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default LoginForm;
