import React from 'react';

function LoadingIndicator() {
    return (
        <div className="bg-white rounded-full p-2 shadow-xl inline-block">
            <div className="loading-spinner w-4 h-4 relative">
                <div className="double-bounce1" />
                <div className="double-bounce2" />
            </div>
        </div>
    );
}

export default LoadingIndicator;
