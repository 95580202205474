import algoliasearch from 'algoliasearch/lite';
import insightsClient from 'search-insights';
import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights';

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN;

insightsClient('init', { appId, apiKey });
export const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({ insightsClient });

export const searchClient = algoliasearch(appId, apiKey);
