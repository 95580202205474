import { useContext, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import AppContext, { CookiePreferences } from 'contexts/AppContext';
import { setCookiePreferences } from 'contexts/AppActions';

export const cookieStorageKey = 'cookies_options';
const useCookies = () => {
    const [storedCookiePreferences, storeCookiePreferences, removeStoredCookiePreferences] = useLocalStorage(
        cookieStorageKey,
        {}
    );
    const { dispatch, state } = useContext(AppContext);

    const changeCookiePreferences = (cookiePreferences?: CookiePreferences) => {
        if (cookiePreferences) {
            storeCookiePreferences(cookiePreferences);
        } else {
            removeStoredCookiePreferences();
        }
    };

    useEffect(() => {
        setCookiePreferences(dispatch, storedCookiePreferences);
    }, [storedCookiePreferences]);

    useEffect(() => {
        if (storedCookiePreferences) {
            setCookiePreferences(dispatch, storedCookiePreferences);
        }
    }, []);

    return { changeCookiePreferences, cookiePreferences: state.cookiePreferences };
};

export default useCookies;
