import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import AppContext from 'contexts/AppContext';
import useCookies from 'hooks/useCookies';
import { setTagManagerInitialized } from 'contexts/AppActions';

const CookieNotice = dynamic(() => import('./CookieNotice'));

let cookiesTimeout = null;

function CookieSettings() {
    const [showCookieNotice, setShowCookieNotice] = useState(false);
    const { dispatch } = useContext(AppContext);
    const { changeCookiePreferences, cookiePreferences } = useCookies();

    const handleCookiesAccept = () => {
        changeCookiePreferences({ advertising: true, analytics: true });
    };

    useEffect(() => {
        if (cookiePreferences?.analytics || cookiePreferences?.advertising) {
            // TODO: Add user ID if user is logged in
            TagManager.initialize({ gtmId: 'GTM-MTJPJQC' });
            setTagManagerInitialized(dispatch);
            setShowCookieNotice(false);
            clearTimeout(cookiesTimeout);
        } else if (cookiePreferences?.deactivated) {
            setShowCookieNotice(false);
            clearTimeout(cookiesTimeout);
        } else if (cookiePreferences?.advertising === false && cookiePreferences?.analytics === false) {
            setShowCookieNotice(false);
            clearTimeout(cookiesTimeout);
        } else {
            cookiesTimeout = setTimeout(() => {
                setShowCookieNotice(true);
            }, 1500);
        }
    }, [cookiePreferences]);
    return <CookieNotice onAccept={handleCookiesAccept} show={showCookieNotice} />;
}

export default CookieSettings;
