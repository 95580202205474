import { X } from 'kzv-icons';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { useKey, useLockBodyScroll } from 'react-use';

import AppContext from 'contexts/AppContext';
import IconButton from 'components/buttons/IconButton';
import LoadingIndicator from 'components/LoadingIndicator';
import AutocompleteSearch from 'components/search/autocomplete/AutocompleteSearch';
import { setSearchDialogActive } from 'contexts/AppActions';

function LockBodyScroll() {
    useLockBodyScroll(true);
    return null;
}

export const DynamicSearchAutoComplete = dynamic(() => import('./AutocompleteSearch'), {
    loading: function AutocompleteLoading() {
        return (
            <div className="flex flex-col justify-center items-center gap-4">
                <div>Nalagam ...</div>
                <div>
                    <LoadingIndicator />
                </div>
            </div>
        );
    }
});

function SearchModal() {
    const { state, dispatch } = useContext(AppContext);
    const router = useRouter();
    useKey('Escape', () => setSearchDialogActive(dispatch, false));

    useEffect(() => {
        setSearchDialogActive(dispatch, false);
    }, [router.asPath]);

    if (!state.searchDialogActive) return null;
    return (
        <div className="w-screen h-screen fixed top-0 left-0 backdrop-filter backdrop-blur-sm bg-opacity-80 bg-brand z-[110]">
            <LockBodyScroll />
            <div className="container mx-auto px-4 py-2 md:py-8">
                <div className="flex justify-between items-center">
                    <div className="logo h-14 sm:h-16">
                        <img src="/images/logo.svg" alt="Kam za vikend logo" className="h-full" />
                    </div>
                    <div className="close text-4xl">
                        <IconButton onClick={() => setSearchDialogActive(dispatch, false)}>
                            <X strokeWidth={1.5} />
                        </IconButton>
                    </div>
                </div>

                {router.asPath.includes('foto-izbor') ? (
                    <div>
                        <h2 className="mt-4 md:mt-24 text-center text-primary">
                            Poišči izletno točko in naloži fotografije.
                        </h2>
                        <p className="mt-2 text-center">V primeru, da izletne točke ne najdeš, lahko dodaš novo.</p>
                    </div>
                ) : (
                    <h2 className="mt-4 md:mt-24 text-center text-primary">Išči med več kot 2500 izletnimi idejami</h2>
                )}

                <div className="mt-6">
                    <AutocompleteSearch autoFocus={true} />
                </div>
            </div>
        </div>
    );
}

export default SearchModal;
