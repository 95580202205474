import { AppState, initialAppState } from 'contexts/AppContext';
import { AppAction, AppActionType } from 'contexts/AppActions';

const appReducer = (state: AppState, action: AppAction): typeof initialAppState => {
    switch (action.type) {
        case AppActionType.SetSearchDialogActive: {
            const { active } = action;
            return {
                ...state,
                searchDialogActive: active
            };
        }
        case AppActionType.SetLoginDialogActive: {
            const { active } = action;
            return {
                ...state,
                loginDialogActive: active
            };
        }
        case AppActionType.SetTagManagerInitialized: {
            return {
                ...state,
                tagManagerInitialized: true
            };
        }
        case AppActionType.SetCookiePreferences: {
            const { preferences } = action;
            return {
                ...state,
                cookiePreferences: preferences
            };
        }

        case AppActionType.SetClientLocation: {
            const { location } = action;
            return {
                ...state,
                clientLocation: location
            };
        }
        case AppActionType.SetUser: {
            const { user } = action;
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: user,
                    isLoading: false,
                    error: null
                }
            };
        }

        case AppActionType.SetUserError: {
            const { error } = action;
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: null,
                    isLoading: false,
                    error: error
                }
            };
        }

        case AppActionType.SetUserLoggingOut: {
            const { loggingOut } = action;
            return {
                ...state,
                user: {
                    ...state.user,
                    loggingOut: loggingOut
                }
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default appReducer;
