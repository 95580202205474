import React from 'react';

import HeadCurveSvg from './svg/HeadCurveSvg';

function HeadCurve() {
    return (
        <div className="head-curve overflow-hidden h-6 3xl:h-8 z-[100] relative">
            <HeadCurveSvg width="110%" className="transform -translate-x-2 -translate-y-3 3xl:-translate-y-0" />
        </div>
    );
}

export default HeadCurve;
