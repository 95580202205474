// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createAutocomplete } from '@algolia/autocomplete-core';
import { Lupa, PuscicaDesno, X } from 'kzv-icons';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Button from 'components/buttons/Button';
import IconButton from 'components/buttons/IconButton';
import PoiTypeIcon from 'components/icons/PoiTypeIcon';
import LoadingSpinner from 'components/LoadingSpinner';
import { Mixpanel } from 'utils/Mixpanel';
import AutocompleteFocusedDropdown from 'components/search/autocomplete/AutocompleteFocusedDropdown';
import { algoliaInsightsPlugin } from 'components/search/autocomplete/searchClient';
import { areasPlugin } from 'components/search/autocomplete/plugins/areasPlugin';
import { poisPlugin } from 'components/search/autocomplete/plugins/poisPlugin';
import parse from 'html-react-parser';
import { HelpCircle } from 'react-feather';

interface AutocompleteSearchProps {
    autoFocus?: boolean;
    handleReset?: () => void;
    initQuery?: string;
    placeholder?: string;
}

function AutocompleteSearch({
    autoFocus = false,
    handleReset,
    initQuery = '',
    placeholder = 'Poišči izletno točko'
}: AutocompleteSearchProps) {
    const [autocompleteState, setAutocompleteState] = useState({
        collections: [],
        completion: null,
        context: {},
        isOpen: false,
        query: '',
        activeItemId: null,
        status: 'idle',
        dirty: false
    });

    const inputRef = useRef(null);
    const formRef = useRef(null);
    const panelRef = useRef(null);
    const router = useRouter();

    const hasResults = useMemo(() => {
        return autocompleteState.collections.reduce((prev, curr) => prev + curr.items.length, 0) > 0;
    }, [autocompleteState]);

    const autocomplete = useMemo(
        () =>
            createAutocomplete({
                openOnFocus: true,
                autoFocus: autoFocus,
                debug: true,
                insights: true,
                initialState: {
                    query: initQuery
                },
                placeholder: placeholder,
                plugins: [algoliaInsightsPlugin, areasPlugin, poisPlugin],
                onStateChange({ state }) {
                    const dirty = state.query.length > 0 && state.query !== initQuery;
                    setAutocompleteState({ ...state, dirty });
                },
                onReset() {
                    setAutocompleteState({
                        ...autocompleteState,
                        query: '',
                        dirty: false
                    });
                    if (typeof handleReset === 'function') {
                        handleReset();
                    }
                },
                navigator: {
                    navigate({ itemUrl }) {
                        Mixpanel.track('Search result selected', {
                            url: itemUrl
                        });
                        router.push(itemUrl);
                    },
                    navigateNewTab({ itemUrl }) {
                        const windowReference = window.open(itemUrl, '_blank', 'noopener');

                        if (windowReference) {
                            windowReference.focus();
                        }
                    },
                    navigateNewWindow({ itemUrl }) {
                        window.open(itemUrl, '_blank', 'noopener');
                    }
                }
            }),
        [router]
    );

    useEffect(() => {
        if (initQuery) {
            autocomplete.setQuery(initQuery);
        }
    }, [initQuery]);

    const searchResultClicked = (slug) => {
        Mixpanel.track('Search result selected', {
            slug: slug
        });
    };

    useEffect(() => {
        if (!formRef.current || !panelRef.current || !inputRef.current) {
            return undefined;
        }

        const { onTouchStart, onTouchMove } = getEnvironmentProps({
            formElement: formRef.current,
            inputElement: inputRef.current,
            panelElement: panelRef.current
        });

        window.addEventListener('touchstart', onTouchStart);
        window.addEventListener('touchmove', onTouchMove);

        return () => {
            window.removeEventListener('touchstart', onTouchStart);
            window.removeEventListener('touchmove', onTouchMove);
        };
    }, [formRef, inputRef, panelRef]);

    const { getEnvironmentProps, getFormProps, getInputProps, getPanelProps, getItemProps, getListProps } =
        autocomplete;

    return (
        <div className="max-w-lg mx-auto relative z-40" {...autocomplete.getRootProps({})}>
            <div className={'group'}>
                <form
                    ref={formRef}
                    className="flex rounded-full items-center bg-white shadow-md focus-within:border-brand hover:border-brand border border-gray-400 transition-all relative z-20"
                    {...getFormProps({ inputElement: inputRef.current })}
                >
                    <div className="flex items-center flex-shrink-0 text-2xl px-2">
                        <label className="h-6" {...autocomplete.getLabelProps({})}>
                            {autocompleteState.status === 'stalled' ? <LoadingSpinner color="#FBA919" /> : <Lupa />}
                        </label>
                    </div>
                    <div className="flex-grow">
                        <input
                            className="appearance-none w-full sm:text-lg ring-0 focus:ring-0 focus:outline-none border-none placeholder-gray-500 placeholder-opacity-75 bg-transparent py-2 px-2"
                            placeholder={placeholder}
                            ref={inputRef}
                            {...getInputProps({ inputElement: inputRef.current })}
                        />
                    </div>
                    <div className={`flex items-center text-xl ${autocompleteState.query ? 'block' : 'hidden'}`}>
                        <IconButton title="Clear" type="reset">
                            <X />
                        </IconButton>
                    </div>
                </form>
                {!hasResults && autocompleteState.query.trim() === '' && (
                    <div
                        className="group-focus-within:block hidden absolute left-0 top-0 z-10 bg-white pt-12 pb-5 rounded-3xl w-full shadow-2xl"
                        {...getPanelProps()}
                    >
                        <AutocompleteFocusedDropdown />
                    </div>
                )}
            </div>
            {autocompleteState.isOpen && (
                <div
                    ref={panelRef}
                    className={`bg-white rounded-3xl shadow-2xl absolute z-10 transition-all mt-2 w-full overflow-hidden ${
                        autocompleteState.status === 'stalled' ? 'opacity-60' : ''
                    }`}
                    {...getPanelProps()}
                >
                    {hasResults && (
                        <div className="h-full relative max-h-[500px] overflow-y-auto overflow-x-hidden">
                            {autocompleteState.collections.map((collection, index) => {
                                const { source, items } = collection;

                                return (
                                    <section key={`source-${index}`} className="aa-Source">
                                        {items.length > 0 && (
                                            <ul className="aa-List  border border-b-gray-400" {...getListProps()}>
                                                {items.map((item) => {
                                                    let url = `/${
                                                        source.sourceId === 'areasPlugin' ? 'ideje' : 'ideja'
                                                    }/${item.slug}`;
                                                    const currentPath = router.pathname;
                                                    if (
                                                        source.sourceId === 'areasPlugin' &&
                                                        currentPath.includes('foto-izbor')
                                                    ) {
                                                        url = `/ideje/${item.slug}/nagradni-izbor`;
                                                    }
                                                    return (
                                                        <li
                                                            key={item.objectID}
                                                            className="autocomplete-item"
                                                            {...getItemProps({ item, source })}
                                                        >
                                                            <NextLink
                                                                href={url}
                                                                onClick={() => searchResultClicked(item.slug)}
                                                            >
                                                                <div className="px-4 py-3 flex items-center">
                                                                    <div className="w-12">
                                                                        {source.sourceId === 'poisPlugin' &&
                                                                            item?.type?.slug && (
                                                                                <PoiTypeIcon
                                                                                    slug={item.type.slug}
                                                                                    size={32}
                                                                                />
                                                                            )}
                                                                    </div>

                                                                    <div className="flex-grow">
                                                                        <div className="leading-none">
                                                                            <div className="flex gap-2 items-center">
                                                                                <span>
                                                                                    {parse(
                                                                                        item._highlightResult?.name
                                                                                            .value
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            {item?.municipality && item?.region && (
                                                                                <small className="text-secondary font-light">
                                                                                    {item?.municipality}, {item?.region}
                                                                                </small>
                                                                            )}
                                                                            {item?.parent && (
                                                                                <small className="text-secondary font-light">
                                                                                    {item?.parent?.name}
                                                                                </small>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-2xl leading-none">
                                                                        <button
                                                                            type="button"
                                                                            title="Select"
                                                                            style={{ pointerEvents: 'none' }}
                                                                        >
                                                                            <PuscicaDesno />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </NextLink>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </section>
                                );
                            })}
                            <ul>
                                <li className="autocomplete-item bg-brand-dull">
                                    <div className="p-4 flex items-center">
                                        <div className="w-12 text-3xl text-brand">
                                            <HelpCircle />
                                        </div>
                                        <div className="flex-grow">
                                            <div className="leading-none text-brand font-medium">
                                                <p>Izletne točke ni na seznamu?</p>
                                            </div>
                                        </div>
                                        <div className="leading-none">
                                            <Button color="secondary" link="/predlagaj-idejo">
                                                Dodaj izletno točko
                                            </Button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
            {!hasResults && autocompleteState.query.trim() !== '' && autocompleteState.dirty && (
                <div
                    className={`bg-white rounded-3xl shadow-2xl absolute z-10 transition-all mt-2 w-full overflow-hidden p-6`}
                >
                    <strong>
                        Za iskalni niz &apos;
                        {autocompleteState.query}
                        &apos; ne najdemo zadetkov.
                    </strong>
                    <p className="my-4">
                        Če meniš, da bi izletna ideja morala biti v naši bazi, jo lahko preprosto dodaš.
                    </p>
                    <div>
                        <Button color="secondary" link="/predlagaj-idejo">
                            Dodaj izletno točko
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AutocompleteSearch;
