import axios from 'axios';

const axiosSettings = {
    baseURL: process.env.NEXT_PUBLIC_API_BASE,
    headers: {
        Accept: 'application/json'
    },
    withCredentials: true
};

if (typeof window === 'undefined') {
    axiosSettings.headers['referer'] = process.env.NEXT_PUBLIC_APP_URL;
}

const customAxios = axios.create(axiosSettings);
export default customAxios;
