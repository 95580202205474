import React from 'react';
import Link from 'next/link';
import authConfig from 'config/auth';
import LoginForm from 'components/forms/LoginForm';
import Button from 'components/buttons/Button';
import { FbCircle } from 'kzv-icons';
import { useLocalStorage } from 'react-use';
import { loginRedirectStorageKey } from 'data/constants';
import { UserProfile } from 'models/UserProfile';

interface LoginProps {
    title?: string;
    onLoginSuccess?: (user: UserProfile) => void;
}

const Login = ({ title = 'Prijavi se ali ustvari račun', onLoginSuccess }: LoginProps) => {
    const [, setLoginRedirect] = useLocalStorage(loginRedirectStorageKey, null);

    const onSocialLoginInitiated = () => {
        setLoginRedirect(window.location.pathname);
    };

    const onRegisterClick = () => {
        setLoginRedirect(window.location.pathname);
    };

    return (
        <div>
            <h2 className="text-center">{title}</h2>

            <div className="social-logins flex flex-col w-[260px] mx-auto gap-4 mt-8">
                <Link
                    onClick={onSocialLoginInitiated}
                    href={authConfig.facebookEndpoint}
                    className="flex gap-2 items-center justify-center px-4 rounded-full py-3 text-white bg-[#3E5293] hover:ring-brand focus:ring-2 hover:ring-2 focus:ring-brand"
                >
                    <FbCircle width={24} height={24} /> Nadaljuj s Facebookom
                </Link>
                <Link
                    onClick={onSocialLoginInitiated}
                    href={authConfig.googleEndpoint}
                    className="flex gap-2 items-center justify-center px-4 rounded-full py-3 text-primary bg-white hover:ring-brand focus:ring-2 hover:ring-2 focus:ring-brand border-black border"
                >
                    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_4898_16846)">
                            <path
                                d="M20 10.2216C20 9.39931 19.9324 8.7997 19.7849 8.17728H10.2045V11.8879H15.8277C15.7147 12.8101 15.1017 14.1994 13.7416 15.1325L13.7228 15.2564L16.7522 17.5559L16.9617 17.5766C18.8893 15.8321 20 13.2653 20 10.2216Z"
                                fill="#4285F4"
                            />
                            <path
                                d="M10.2045 20C12.9601 20 15.2724 19.1115 16.9617 17.5777L13.7416 15.1336C12.8803 15.7224 11.7231 16.1341 10.2045 16.1341C7.5065 16.1341 5.21531 14.3895 4.39948 11.9792L4.27977 11.9889L1.12951 14.3776L1.0885 14.4895C2.76671 17.7558 6.21293 20 10.2045 20Z"
                                fill="#34A853"
                            />
                            <path
                                d="M4.39949 11.9781C4.18445 11.3556 4.05919 10.6887 4.05919 10C4.05919 9.31131 4.18334 8.64436 4.38841 8.02194L4.38286 7.88942L1.19271 5.46274L1.08851 5.51162C0.39683 6.86617 0 8.38909 0 10C0 11.6109 0.39683 13.1327 1.08851 14.4884L4.39949 11.977"
                                fill="#FBBC05"
                            />
                            <path
                                d="M10.2045 3.86704C12.121 3.86704 13.4135 4.67847 14.1506 5.35629L17.0304 2.60048C15.2613 0.988486 12.959 0 10.2045 0C6.21293 0 2.76671 2.24419 1.0885 5.51054L4.38841 8.02194C5.21643 5.61047 7.5065 3.86596 10.2045 3.86596"
                                fill="#EB4335"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_4898_16846">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Nadaljuj z Googlom
                </Link>
            </div>

            <div className="divider flex gap-6 mt-8 mb-6 items-center">
                <div className="w-full h-[1px] bg-gray-300"></div>
                <div className="font-serif flex-shrink-0">Prijava z e-pošto</div>
                <div className="w-full h-[1px] bg-gray-300"></div>
            </div>

            <div className="email-pass-form mx-auto w-[260px]">
                <LoginForm onLoginSuccess={onLoginSuccess} />
            </div>

            <div className="flex flex-col justify-center items-center gap-2 mt-8">
                <span className="text-sm">Še nimaš računa?</span>
                <Button
                    styles="font-bold min-w-[260px]"
                    variant="text"
                    color={'secondary'}
                    link="/registracija"
                    onClick={onRegisterClick}
                >
                    Ustvari nov račun
                </Button>
            </div>
        </div>
    );
};

export default Login;
