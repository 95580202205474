import { Drsanje, Pravljicno, useIcons } from 'kzv-icons';
import React from 'react';

const poiTypeslugIconsMapper = {
    drsalisce: Drsanje,
    'pravljicna-pot': Pravljicno
};

interface PoiTypeIconProps {
    slug: string;
    size?: number | string;
}

function PoiTypeIcon({ slug, size, ...rest }: PoiTypeIconProps) {
    const { poiTypesIcons } = useIcons();

    const Icon = poiTypesIcons[slug] ?? poiTypeslugIconsMapper[slug];

    if (!Icon) {
        return null;
    }

    return (
        <div className="relative">
            <Icon style={{ width: size, height: size }} {...rest} />
        </div>
    );
}

export default PoiTypeIcon;
