import * as React from 'react';

function UnderlineCurveSvg({ color = '#F4F4F5', strokeWidth = 20, ...rest }) {
    return (
        <svg
            width="100%"
            viewBox="0 0 295 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            {...rest}
        >
            <path
                d="M10 10c6.126.12 11.884.63 18.101.63 6.57 0 13.519.679 20.267.679 7.577 0 14.544.176 21.97.98 20.119 2.179 40.542-1.309 60.724-1.694 7.169-.137 14.318.64 21.505.64 16.638 0 29.308-.64 49.701 1.13 8.992-1.059 18.254-.378 27.307-.378 9.902 0 20.608 1.026 30.478-.377 6.262-.89 7.802 1.36 13.871-.374 4.351.508 9.095-1.163 11.076-1.163"
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default UnderlineCurveSvg;
