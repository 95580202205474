/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect } from 'react';

import appReducer from './AppReducer';
import { AppAction, setUser, setUserError } from 'contexts/AppActions';
import { IGeolocationPositionError } from 'react-use/lib/useGeolocation';
import customAxios from 'data/axios';
import { User } from 'models/User';
import mapUserProfile from 'models/mapping/mapUserProfile';
import useSession from 'hooks/useSession';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'react-use';
import { loginRedirectStorageKey } from 'data/constants';
import toast from 'react-hot-toast';

export interface CookiePreferences {
    advertising?: boolean;
    analytics?: boolean;
    deactivated?: boolean;
}

interface PhotoContestState {
    state: 'IDLE' | 'ACTIVE' | 'VOTING' | 'FINISHED';
}

export interface ClientLocation {
    loading: boolean;
    latitude?: number;
    longitude?: number;
    error?: Error | IGeolocationPositionError;
    timestamp: number;
}

export interface AppState {
    tagManagerInitialized: boolean;
    cookiePreferences: CookiePreferences;
    searchDialogActive: boolean;
    loginDialogActive: boolean;
    photoContest: PhotoContestState;
    clientLocation: ClientLocation;
    user: User;
}

export const initialAppState: AppState = {
    tagManagerInitialized: false,
    cookiePreferences: {},
    searchDialogActive: false,
    loginDialogActive: false,
    photoContest: {
        state: 'FINISHED'
    },
    clientLocation: {
        loading: false,
        latitude: undefined,
        longitude: undefined,
        error: undefined,
        timestamp: Date.now()
    },
    user: {
        profile: undefined,
        error: undefined,
        isLoading: true,
        accessToken: undefined,
        loggingOut: false
    }
};

export interface AppContext {
    state: AppState;
    dispatch: React.Dispatch<AppAction>;
}

const AppContext = createContext<AppContext>({
    state: initialAppState,
    dispatch: () => null
});

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = React.useReducer(appReducer, initialAppState as AppState);
    const value = { state, dispatch };
    const { token } = useSession();
    const [loginRedirect, setLoginRedirect] = useLocalStorage(loginRedirectStorageKey, null);
    const router = useRouter();

    useEffect(() => {
        if (!token) return;
        customAxios
            .get('me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                const user = mapUserProfile(response.data?.data);
                customAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                setUser(dispatch, user);
                if (!user.completed_registration_at) {
                    router.push('/zakljuci-registracijo');
                } else if (loginRedirect) {
                    toast.success(`Živjo ${user.first_name}, lepo da si nazaj.`, { duration: 5000, icon: '👋' });
                    router.push(loginRedirect);
                    setLoginRedirect(null);
                }
            })
            .catch((error) => {
                setUserError(dispatch, error);
            });
    }, [token]);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContext;
