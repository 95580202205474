import React from 'react';

import UnderlineCurveSvg from './svg/UnderlineCurveSvg';

function LinkUnderlineCurve({ color, children }) {
    return (
        <span className="inline-block relative whitespace-nowrap">
            <span className="z-10 relative">{children}</span>
            <span className="inline-block absolute left-2 right-2  bottom-0  z-[-1] pointer-events-none">
                <UnderlineCurveSvg height={12} color={color} />
            </span>
        </span>
    );
}

export default LinkUnderlineCurve;
