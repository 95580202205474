import { Dispatch } from 'react';
import { CookiePreferences } from 'contexts/AppContext';
import { UserProfile } from 'models/UserProfile';

export enum AppActionType {
    SetSearchDialogActive = 'SET_SEARCH_DIALOG_ACTIVE',
    SetLoginDialogActive = 'SET_LOGIN_DIALOG_ACTIVE',
    SetTagManagerInitialized = 'SET_TAG_MANAGER_INITIALIZED',
    SetCookiePreferences = 'SET_COOKIE_PREFERENCES',
    SetClientLocation = 'SET_CLIENT_LOCATION',
    SetUser = 'SET_USER',
    SetUserError = 'SET_USER_ERROR',
    SetUserLoggingOut = 'SET_USER_LOGGING_OUT'
}

export type AppAction = {
    type: AppActionType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

const setSearchDialogActive = (dispatch: Dispatch<AppAction>, active: boolean) => {
    dispatch({ type: AppActionType.SetSearchDialogActive, active });
};

const setLoginDialogActive = (dispatch: Dispatch<AppAction>, active: boolean) => {
    dispatch({ type: AppActionType.SetLoginDialogActive, active });
};

const setTagManagerInitialized = (dispatch: Dispatch<AppAction>) => {
    dispatch({ type: AppActionType.SetTagManagerInitialized });
};

const setCookiePreferences = (dispatch: Dispatch<AppAction>, preferences: CookiePreferences) => {
    dispatch({ type: AppActionType.SetCookiePreferences, preferences });
};

const setClientLocation = (dispatch: Dispatch<AppAction>, location) => {
    dispatch({ type: AppActionType.SetClientLocation, location });
};

const setUser = (dispatch: Dispatch<AppAction>, user: UserProfile) => {
    dispatch({ type: AppActionType.SetUser, user });
};

const setUserError = (dispatch: Dispatch<AppAction>, error) => {
    dispatch({ type: AppActionType.SetUserError, error });
};

const setUserLoggingOut = (dispatch: Dispatch<AppAction>, loggingOut) => {
    dispatch({ type: AppActionType.SetUserLoggingOut, loggingOut });
};

export {
    setSearchDialogActive,
    setTagManagerInitialized,
    setCookiePreferences,
    setClientLocation,
    setLoginDialogActive,
    setUser,
    setUserError,
    setUserLoggingOut
};
