import React, { useContext } from 'react';
import BaseModal from 'components/modals/BaseModal';
import AppContext from 'contexts/AppContext';
import { useLockBodyScroll } from 'react-use';
import { setLoginDialogActive } from 'contexts/AppActions';
import { X } from 'kzv-icons';
import IconButton from 'components/buttons/IconButton';
import Login from 'components/auth/Login';
import toast from 'react-hot-toast';

function LockBodyScroll() {
    useLockBodyScroll(true);
    return null;
}

interface LoginModalProps {
    title?: string;
}

const LoginModal = ({ title }: LoginModalProps) => {
    const { state, dispatch } = useContext(AppContext);
    const closeModal = () => {
        setLoginDialogActive(dispatch, false);
    };

    const handleLoginSuccess = (user) => {
        closeModal();
        toast.success(`Živjo ${user.first_name}, lepo da si nazaj.`, { duration: 5000, icon: '👋' });
    };

    return (
        <>
            <BaseModal open={state.loginDialogActive} handleClose={closeModal}>
                <LockBodyScroll />
                <div className="w-full min-h-full sm:w-[520px] sm:relative sm:h-auto py-8 bg-white flex flex-col justify-between sm:px-12 px-4">
                    <div className="sm:hidden absolute top-0 right-0 text-2xl">
                        <IconButton color="secondary" onClick={closeModal}>
                            <X strokeWidth={2} />
                        </IconButton>
                    </div>
                    <Login title={title} onLoginSuccess={handleLoginSuccess} />
                </div>
            </BaseModal>
        </>
    );
};

export default LoginModal;
