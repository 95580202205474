import React from 'react';
import useUser from 'hooks/useUser';

const UserAvatar = ({
    size = 'small',
    clickable = false
}: {
    size?: 'small' | 'large' | 'full';
    clickable?: boolean;
}) => {
    const { user } = useUser();

    const sizeClasses = {
        small: 'w-[30px] h-[30px]',
        large: 'w-[80px] h-[80px]',
        full: 'w-full h-full'
    };

    const clickableClasses = 'transition-all hover:shadow-lg focus:shadow-lg';

    return (
        <div
            className={`avatar shadow-md rounded-br-full rounded-tl-full rounded-tr-full border border-[#414142] text-primary ${
                sizeClasses[size]
            } ${clickable && clickableClasses} overflow-hidden bg-white`}
        >
            {user.avatar && (
                <img
                    src={user.avatar}
                    alt={user.first_name + ' ' + user.last_name}
                    className="w-full h-full object-cover"
                />
            )}
        </div>
    );
};

export default UserAvatar;
