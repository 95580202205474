import React from 'react';

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    disabled?: boolean;
    styles?: string;
    size?: 'base' | 'small';
}
function IconButton({ children, disabled, styles, size = 'base', ...rest }: IconButtonProps) {
    let appliedStyles = 'transition-all hover:shadow-sm rounded-full transition duration-60';

    if (size === 'base') {
        appliedStyles += ' p-3';
    } else if (size === 'small') {
        appliedStyles += ' p-2 text-sm';
    }

    if (!disabled) {
        appliedStyles += ' hover:bg-gray-100 focus:outline-none focus:ring-brand focus:ring-2 active:bg-gray-200';
    } else {
        appliedStyles += ' cursor-auto opacity-50';
    }

    appliedStyles += ` ${styles}`;

    return (
        <button {...rest} className={appliedStyles} disabled={disabled}>
            {children}
        </button>
    );
}

export default IconButton;
